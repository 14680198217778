import { FunctionComponent } from 'react';
import { useTracking } from '@common/analytics/Analytics';
import CustomModal from '@common/basics/Modal/CustomModal';
import { useI18n } from '@common/language/i18n';
import Heading, {
  HEADING_CONTENT_ID,
} from '@common/pageBuildingBlocks/Heading';
import { CloseButton } from '@hedwig/buttons';
import Card from '@hedwig/cards/Card';
import { IconSet } from '@hedwig/icons';
import { FRONTPAGE_CLICK } from '../../organization/constants';

const LoginModal: FunctionComponent<LoginModalProps> = ({
  expanded,
  setExpanded,
  ariaLabelledby,
}) => {
  const [translation] = useI18n();
  const { registerAnalyticsEvent } = useTracking();
  return (
    <CustomModal
      ariaLabelledby={ariaLabelledby}
      ariaDescribedby={HEADING_CONTENT_ID}
      width="920px"
      isOpen={expanded}
      onClick={() => setExpanded(false)}
    >
      <CloseButton
        onClick={() => setExpanded(false)}
        iconSet={IconSet.REGULAR}
      />
      <Heading
        headerText={translation.common.frontpage.login.heading}
        sizeClass="hw-h2"
        extraClassName="hw-text-center"
      />
      <div className="hw-flex hw-block--mt-large-1">
        <a
          data-testid="login-modal-sign-button"
          href={`${window.location.origin}/logginn`}
          onClick={() => {
            setExpanded(false);
            registerAnalyticsEvent({
              category: 'Landingsside',
              action: FRONTPAGE_CLICK,
              label: 'Logg inn modal: Trykket på logg inn for å signere',
            });
          }}
        >
          <Card
            title={translation.portal.login.modal.sign}
            description={translation.common.frontpage.login.sign_text}
            linkText={translation.common.frontpage.login.sign_link}
            extraWrapperClassName="login-modal__card"
          />
        </a>
        <a
          data-testid="login-modal-business-button"
          href={`${window.location.origin}/virksomhet/logginn`}
          onClick={() => {
            setExpanded(false);
            registerAnalyticsEvent({
              category: 'Landingsside',
              action: FRONTPAGE_CLICK,
              label: 'Logg inn modal: Trykket på logg inn som virksomhet',
            });
          }}
        >
          <Card
            title={translation.portal.frontpage.sender_login}
            description={translation.common.frontpage.login.send_text}
            linkText={translation.common.frontpage.login.send_link}
            extraWrapperClassName="login-modal__card login-modal__card-last"
            extraBodyClassName="hw-block--mb-medium-1"
          />
        </a>
      </div>
    </CustomModal>
  );
};

interface LoginModalProps {
  expanded: boolean;
  setExpanded: (value: boolean) => void;
  ariaLabelledby: string;
}

export default LoginModal;
