export enum ValidationType {
  INVALID_FIELD = 'INVALID_FIELD',
  NOT_VALIDATED = 'NOT_VALIDATED',
  VALID = 'VALID',
}

interface ValidationError {
  result: ValidationType.INVALID_FIELD;
  errorMessage: string;
  shortErrorMessage?: string;
}
interface NotValidated {
  result: ValidationType.NOT_VALIDATED;
}
interface Valid {
  result: ValidationType.VALID;
}
export type Validation = NotValidated | Valid | ValidationError;

export type ValidationWithError = ValidationError;
export type Validations = { [key: string]: Validation };
