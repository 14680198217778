import { FunctionComponent } from 'react';
import { addClassesFromProps } from '@common/basicFunctions';

interface CardProps {
  title: string;
  description: string;
  linkText: string;
  extraWrapperClassName?: string;
  extraBodyClassName?: string;
}

const Card: FunctionComponent<CardProps> = ({
  title,
  description,
  linkText,
  extraWrapperClassName,
  extraBodyClassName,
}) => {
  return (
    <div className={`hw-card${addClassesFromProps(extraWrapperClassName)}`}>
      <div
        className={`hw-card__body${addClassesFromProps(extraBodyClassName)}`}
      >
        <h2 className="hw-card__title">{title}</h2>
        <p className="hw-card__description">{description}</p>
      </div>
      <div className="hw-card__footer">
        <span className="hw-link hw-link--small">{linkText}</span>
      </div>
    </div>
  );
};

export default Card;
