import { FunctionComponent, PropsWithChildren } from 'react';

import Curtain from '@hedwig/curtain/Curtain';

interface HamburgerDropdownMenuProps {
  text: string;
}

const HamburgerDropdownMenu: FunctionComponent<
  HamburgerDropdownMenuProps & PropsWithChildren
> = ({ text, children }) => {
  return (
    <Curtain
      text={text}
      curtainClass="hamburger__list-item__curtain"
      wrapperClassName="hw-list hw-list--no-bullets"
      triggerClassName="hamburger__list-item__curtain-heading button-header-tab"
      textClassName="posten-sans-bold"
      contentClassName="hamburger__list-item__curtain-content"
    >
      {children}
    </Curtain>
  );
};

export default HamburgerDropdownMenu;
