import { FunctionComponent, PropsWithChildren } from 'react';

import HeaderLogOutButton from './HeaderLogOutButton';
import LandingPageHeaderLogInButton from '../../landingPage/login/HeaderLogInButton';
import HelpLink from '../help/HelpLink';
import { AppName } from '../analytics/appNames';
import { App } from '../../apps';
import ChangeLanguage from './ChangeLanguage';

import '@less/navbar.less';

interface HeaderRightContentProps {
  loggedIn: boolean;
  app: App;
  goToDifiHelp: boolean;
  linkToDefaultTopic?: string;
  url?: string;
  appName: AppName;
}

const HeaderRightContent: FunctionComponent<
  HeaderRightContentProps & PropsWithChildren
> = ({
  children,
  loggedIn,
  app,
  goToDifiHelp,
  linkToDefaultTopic,
  url,
  appName,
}) => (
  <div className="hw-navbar__menu navbar__right-content hide--small-desktop-and-down hw-flex--gutter-largest-desktop margin-right_zero">
    {children}
    {app !== App.ADMINISTRATOR_PORTAL && loggedIn && url && (
      <HelpLink
        goToDifiHelp={goToDifiHelp}
        openInNewTab={loggedIn}
        linkToDefaultTopic={linkToDefaultTopic}
        url={url}
        appName={appName}
        dataTestid={`help-link-${app}`}
        className="navbar__menu-item"
      />
    )}
    {loggedIn ? (
      <HeaderLogOutButton
        app={app}
        dataTestid={`logout-button-${app}`}
        extraClassName="navbar__menu-item"
      />
    ) : (
      app !== App.ADMINISTRATOR_PORTAL && (
        <>
          <LandingPageHeaderLogInButton app={app} />
          <ChangeLanguage />
        </>
      )
    )}
  </div>
);

export default HeaderRightContent;
