import { useRef, useState } from 'react';

import { GlobeIcon, IconColor, IconSet, IconSize } from '@hedwig/icons';
import { useI18n } from '../language/i18n';
import { useOutsideClick } from '../../landingPage/HeaderDropDown';
import OnClickLanguageButton from '../language/OnClickLanguageButton';

const ChangeLanguage = () => {
  const [translation] = useI18n();
  const [expanded, setExpanded] = useState(false);
  const node = useRef<HTMLDivElement>(null);
  useOutsideClick(node, setExpanded);

  return (
    <div className="hide--small-desktop-and-down margin-right_zero" ref={node}>
      <button
        className="navbar__menu_item-button navbar__menu-item change-language-button"
        aria-expanded={expanded}
        aria-controls="change-language-dropdown"
        onClick={(e) => {
          e.preventDefault();
          setExpanded(!expanded);
        }}
        type="button"
      >
        <GlobeIcon
          size={IconSize.MEDIUM}
          color={IconColor.BLACK}
          set={IconSet.REGULAR}
          extraClassName="globe-icon icon-with-text"
        />
        {translation.common.language}
      </button>
      <div className="navbar__dropdown" aria-live="polite">
        {expanded && (
          <OnClickLanguageButton callback={() => setExpanded(false)} />
        )}
      </div>
    </div>
  );
};

export default ChangeLanguage;
