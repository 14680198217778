import { format } from 'date-fns';

import { OrganizationSector } from '../organizations/organizationSector';
import { IS_PROD } from '../environment';

const JOB_ID_QUERY_PARAM_NAME = 'id';
const SIGNER_ID_QUERY_PARAM_NAME = 'sign_id';
const SIGNATURE_RESULT_QUERY_PARAM_NAME = 'signature_result';
const PAYMENT_RESULT_QUERY_PARAM_NAME = 'payment_result';

const getAnonymizedUrl = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const queryParamsWhitelist = [
    SIGNATURE_RESULT_QUERY_PARAM_NAME,
    PAYMENT_RESULT_QUERY_PARAM_NAME,
  ];

  queryParams.forEach(function (value, key) {
    if (!queryParamsWhitelist.includes(key)) {
      if (key === JOB_ID_QUERY_PARAM_NAME) {
        queryParams.set(JOB_ID_QUERY_PARAM_NAME, 'job_id'); // Descriptive replacement of the anonymized value
      } else if (key === SIGNER_ID_QUERY_PARAM_NAME) {
        queryParams.set(SIGNER_ID_QUERY_PARAM_NAME, 'signer_id'); // Descriptive replacement of anonymized the value
      } else {
        queryParams.set(key, 'anonymized');
      }
    }
  });

  return `${window.location.hostname}${
    window.location.pathname
  }?${queryParams.toString()}`;
};

const mapSector = (sector?: OrganizationSector) => {
  if (sector === OrganizationSector.PUBLIC) {
    return 'offentlig';
  } else if (sector === OrganizationSector.PRIVATE) {
    return 'privat';
  } else {
    return '';
  }
};

const sendPopupSurveyResponseToGoogleSheets = (popupSurveyResponse: {
  userType: string;
  product: string;
  sector?: OrganizationSector;
  context: string;
  trigger: string;
  satisfactionScore: number;
  comment: string;
  id: number | string | undefined;
}) => {
  const body = {
    Tid: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
    Brukertype: popupSurveyResponse.userType,
    Produkt: popupSurveyResponse.product,
    Sektor: mapSector(popupSurveyResponse.sector),
    Kontekst: popupSurveyResponse.context,
    Trigger: popupSurveyResponse.trigger,
    Tilfredshetsscore: popupSurveyResponse.satisfactionScore,
    Kommentar: popupSurveyResponse.comment,
    URL: getAnonymizedUrl(),
    OrgId: popupSurveyResponse.id,
    Environment: IS_PROD ? 'PROD' : 'TEST',
  };
  const options = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    },
    body: JSON.stringify(body),
  };
  const url =
    'https://script.google.com/macros/s/AKfycbyoOTviWbQn4xC9r52S-qqNknwNnUqilM2WDYaYYMjaHFLpymj_yaMkAK-kKMYQcGQ/exec';
  return fetch(url, options);
};

export { sendPopupSurveyResponseToGoogleSheets, getAnonymizedUrl };
