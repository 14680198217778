import { FunctionComponent, useState } from 'react';

import {
  enkelSigneringSendFeedbackToGoogleSheets,
  postFeedbackToGoogleDocs,
} from '../api';
import { useI18n } from '../../language/i18n';
import FeedbackForm from '../FeedbackForm';
import ThankYouBox from './ThankYouBox';
import config from '../../config';
import CustomModal from '../../basics/Modal/CustomModal';
import { CloseButton } from '@hedwig/buttons';
import { IconSet, IconSize } from '@hedwig/icons';
import { Validation, ValidationType } from '../../validation/validation';

const validateComment = (comment: string) => {
  return comment.trim() !== '';
};

const FeedbackFormMobile: FunctionComponent<{
  showFeedbackForm: boolean;
  setShowFeedbackForm: (value: boolean) => void;
}> = ({ showFeedbackForm, setShowFeedbackForm }) => {
  const [translation, , format] = useI18n();
  const [state, setState] = useState<{
    chosenRating?: number;
    comment: string;
    feedbackSubmitted: boolean;
    commentValidation: Validation;
  }>({
    feedbackSubmitted: false,
    comment: '',
    chosenRating: undefined,
    commentValidation: { result: ValidationType.NOT_VALIDATED },
  });

  const submitFeedback = () => {
    const formData = {
      Tid: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
      URL: window.location.href,
      Applikasjon: 'Unknown',
      Sektor: 'Unknown',
      Rating: state.chosenRating,
      Tilbakemelding: 'Mobil: ' + state.comment,
      Environment: config.isDevModeEnabled ? 'TEST' : 'PROD',
    };
    void postFeedbackToGoogleDocs(formData);
  };

  const enkelSigneringSubmitFeedback = () => {
    void enkelSigneringSendFeedbackToGoogleSheets('Mobil: ' + state.comment);
  };

  const onSubmitFeedback = () => {
    if (validateComment(state.comment)) {
      if (window.location.pathname.includes('privat')) {
        enkelSigneringSubmitFeedback();
      } else {
        submitFeedback();
      }

      setState({
        ...state,
        feedbackSubmitted: true,
        comment: '',
        chosenRating: undefined,
        commentValidation: { result: ValidationType.NOT_VALIDATED },
      });
    } else {
      setState({
        ...state,
        commentValidation: {
          result: ValidationType.INVALID_FIELD,
          errorMessage: translation.common.feedback.comment.error_message,
        },
      });
    }
  };

  return (
    <CustomModal
      isOpen={showFeedbackForm}
      ariaLabelledby="feedback-heading"
      ariaDescribedby="feedback-form-comment"
      padding="0px"
      onClick={() => {
        setShowFeedbackForm(false);
        setState({
          ...state,
          feedbackSubmitted: false,
        });
      }}
    >
      <CloseButton
        onClick={() => setShowFeedbackForm(false)}
        iconSet={IconSet.LIGHT}
        iconSize={IconSize.MEDIUM}
        extraClassName="feedback__close-button--mobile"
      />
      {state.feedbackSubmitted ? (
        <ThankYouBox
          closeForm={() => {
            setShowFeedbackForm(false);
          }}
        />
      ) : (
        <FeedbackForm
          onSubmitFeedback={onSubmitFeedback}
          updateFormData={(updatedFields) => {
            setState({ ...state, ...updatedFields });
          }}
          formData={state}
          closeFeedbackForm={() => setShowFeedbackForm(false)}
        />
      )}
    </CustomModal>
  );
};

export default FeedbackFormMobile;
