import { FunctionComponent, MouseEventHandler } from 'react';
import { SecondaryButton } from '@hedwig/buttons';
import { useI18n } from '../../language/i18n';

const ThankYouBox: FunctionComponent<{
  closeForm: MouseEventHandler<HTMLButtonElement>;
}> = ({ closeForm }) => {
  const [translation] = useI18n();
  return (
    <div className="feedback-form__thank-you-message text-right">
      <h3
        id="feedback-thankyou-heading"
        tabIndex={-1}
        className="hw-h3 hw-text-title hw-block--mb hw-text-center feedback-heading"
      >
        {translation.common.feedback.thankyou.header}
      </h3>
      <p className="thank-you-text">
        {translation.common.feedback.thankyou.text}
      </p>
      <SecondaryButton onClick={closeForm}>
        {translation.common.primarybutton.close}
      </SecondaryButton>
    </div>
  );
};

export default ThankYouBox;
