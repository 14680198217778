import { FunctionComponent, PropsWithChildren } from 'react';

import '@less/navbar.less';

const HeaderLeftContent: FunctionComponent<PropsWithChildren> = ({
  children,
}) => (
  <div className="hw-navbar__menu navbar__selector hide--small-desktop-and-down hw-flex--gutter-largest-desktop">
    {children}
  </div>
);

export default HeaderLeftContent;
