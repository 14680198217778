import { FunctionComponent, ReactElement, useState } from 'react';
import OnClickLanguageButton from '../../language/OnClickLanguageButton';
import HamburgerDropDownMenu from './HamburgerDropdownMenu';
import { CloseIcon } from '@hedwig/icons';
import { useI18n } from '../../language/i18n';
import HamburgerDropdownMenu from './HamburgerDropdownMenu';
import HamburgerElement from './HamburgerElement';
import hamburger from '@images/icons/hamburger.svg';
import FeedbackFormMobile from '@common/feedback/mobile/FeedbackFormMobile';

import './hamburger.less';
import { useHamburgerContext } from '../PostenHeader';

type Child =
  | ReactElement<typeof HamburgerDropdownMenu>
  | ReactElement<typeof HamburgerElement>;

interface HamburgerProps {
  children: Child | Array<Child | null>;
  hideFeedbackForm?: boolean;
}
const Hamburger: FunctionComponent<HamburgerProps> = ({
  hideFeedbackForm = false,
  children,
}) => {
  const [translation] = useI18n();
  const [showFeedbackForm, setShowFeedbackForm] = useState(false);
  const { isHamburgerVisible, setHamburgerVisibility } = useHamburgerContext();

  return (
    <div className="hide--desktop hw-block--mt-small-4">
      <button
        className="hamburger-icon"
        aria-label={
          translation.common[
            isHamburgerVisible ? 'close-hamburger_menu' : 'open-hamburger_menu'
          ]
        }
        aria-expanded={isHamburgerVisible}
        aria-controls="hamburger-children-list"
        onClick={() => setHamburgerVisibility(!isHamburgerVisible)}
        type="button"
      >
        {isHamburgerVisible ? (
          <CloseIcon />
        ) : (
          <img src={hamburger} alt="Hamburger" aria-hidden="true" />
        )}
      </button>

      <div
        id="hamburger-children-list"
        className={`hw-block--mt ${
          isHamburgerVisible ? 'hamburger' : 'hidden'
        }`}
      >
        <ul className="hamburger__list">
          {children}
          <HamburgerDropDownMenu text={translation.common.language}>
            <OnClickLanguageButton
              callback={() => setHamburgerVisibility(false)}
            />
          </HamburgerDropDownMenu>
          {!hideFeedbackForm && (
            <HamburgerElement extraClassName="ui-min">
              <button
                className="posten-sans-bold hamburger__list-element__button hamburger__list-item__button-text"
                onClick={() => setShowFeedbackForm(true)}
                type="button"
              >
                {translation.common.feedback.form.button_mobile}
              </button>
              {showFeedbackForm && (
                <FeedbackFormMobile
                  showFeedbackForm={showFeedbackForm}
                  setShowFeedbackForm={setShowFeedbackForm}
                />
              )}
            </HamburgerElement>
          )}
        </ul>
      </div>
    </div>
  );
};

export default Hamburger;
