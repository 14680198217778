import { FunctionComponent } from 'react';
import { App } from '../../apps';
import { useTracking } from '../analytics/Analytics';
import { addClassesFromProps } from '../basicFunctions';
import { useI18n } from '../language/i18n';

const HeaderLogOutButton: FunctionComponent<{
  app: App;
  dataTestid?: string;
  extraClassName?: string;
}> = ({ app, dataTestid, extraClassName }) => {
  const [translation] = useI18n();
  const { registerAnalyticsEvent } = useTracking();
  return (
    <a
      href="/web/logout"
      className={addClassesFromProps(extraClassName)}
      data-testid={dataTestid}
      onClick={() =>
        registerAnalyticsEvent({
          category: app,
          action: 'Trykket på logg ut i header',
        })
      }
    >
      <span>{translation.logout.button}</span>
    </a>
  );
};

export default HeaderLogOutButton;
