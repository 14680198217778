import { format } from 'date-fns';
import { getAnonymizedUrl } from '../popupSurvey/googleSheets';
import { IS_PROD } from '../environment';

export function postFeedbackToGoogleDocs(data: unknown): Promise<Response> {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
  };
  return fetch(
    'https://script.google.com/macros/s/AKfycbwzTR4fulEjsj8JxAZGZibfnFQJWhyO-qZ2hED0n5BRu1Fay5ty5yrp4zfVqkWa7Kecrw/exec',
    { method: 'POST', headers, body: JSON.stringify(data) }
  );
}

export function enkelSigneringSendFeedbackToGoogleSheets(
  comment: string
): Promise<Response> {
  const body = {
    Tid: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
    URL: getAnonymizedUrl(),
    Tilbakemelding: comment,
    Environment: IS_PROD ? 'PROD' : 'TEST',
  };
  const options = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    },
    body: JSON.stringify(body),
  };
  const url =
    'https://script.google.com/macros/s/AKfycbzSdvp3houPc2mEAAshvfyfPV2a6m0btexJAPo75HHVkTyo-rQiL0wcZS52WntwPqjT/exec';
  return fetch(url, options);
}
