import { Fragment, FunctionComponent, useState } from 'react';
import { App } from '../../apps';
import { useTracking } from '@common/analytics/Analytics';
import { useI18n } from '@common/language/i18n';
import { IconSet, UserIcon } from '@hedwig/icons';
import { FRONTPAGE_CLICK } from '../../organization/constants';
import LoginModal from './LoginModal';

const HeaderLogInButton: FunctionComponent<{ app: App }> = ({ app }) => {
  const [translation] = useI18n();
  const { registerAnalyticsEvent } = useTracking();
  const [expanded, setExpanded] = useState(false);
  return (
    <Fragment>
      <button
        id="header-login-button"
        data-testid="header-login-button"
        className="navbar__menu-item navbar__menu_item-button"
        onClick={() => {
          setExpanded(!expanded);
          registerAnalyticsEvent({
            category: 'Landingsside',
            action: FRONTPAGE_CLICK,
            label: 'Trykket på logg inn i header - ' + app,
          });
        }}
        type="button"
      >
        <UserIcon
          set={IconSet.REGULAR}
          extraClassName="icon-with-text icon-header"
        />
        {translation.login.short}
      </button>
      <LoginModal
        expanded={expanded}
        setExpanded={setExpanded}
        ariaLabelledby="header-login-button"
      />
    </Fragment>
  );
};

export default HeaderLogInButton;
