import { FunctionComponent } from 'react';

const InputErrorText: FunctionComponent<InputErrorTextProps> = ({
  errorMessage,
  errorDataTestid,
  ariaDescribedBy,
}) => {
  return (
    <div
      className="hw-error hw-error--align-left hw-error--indented form-input__error"
      aria-live="polite"
      aria-describedby={ariaDescribedBy}
    >
      <span
        data-testid={errorDataTestid}
        dangerouslySetInnerHTML={{ __html: errorMessage }}
      />
    </div>
  );
};

interface InputErrorTextProps {
  errorDataTestid?: string;
  errorMessage: string;
  ariaDescribedBy?: string;
}

export default InputErrorText;
