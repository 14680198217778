import {
  ChangeEvent,
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
} from 'react';

import { useI18n } from '../language/i18n';
import {
  Button,
  buttonStyles,
  CloseButton,
  PrimaryButton,
  SecondaryOutlinedButton,
  widths,
} from '@hedwig/buttons';
import { IconColor, IconSet, IconSize, StarIcon } from '@hedwig/icons';
import { Validation, ValidationType } from '../validation/validation';
import InputErrorText from '@hedwig/forms/InputErrorText';

import './feedback.less';

const FeedbackForm: FunctionComponent<FeedbackFormProps> = ({
  formData,
  updateFormData,
  onSubmitFeedback,
  closeFeedbackForm,
}) => {
  const [translation] = useI18n();
  const focus = useRef<HTMLFormElement>(null);
  useEffect(() => {
    focus?.current?.focus();
  }, []);

  const ratings: Array<1 | 2 | 3 | 4 | 5> = [1, 2, 3, 4, 5];
  const setCorrectRatingIconStyle = (rating: number, chosenRating: number) => {
    return rating === chosenRating || rating < chosenRating ? (
      <StarIcon size={IconSize.EXTRA_LARGE} color={IconColor.PRIMARY_DARK} />
    ) : (
      <StarIcon
        size={IconSize.EXTRA_LARGE}
        set={IconSet.LIGHT}
        color={IconColor.PRIMARY_DARK}
      />
    );
  };

  const onChangeText = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>) => {
      updateFormData({ comment: event.target.value });
    },
    [updateFormData]
  );

  return (
    <form
      id="feedback-form"
      className="feedback-form"
      tabIndex={-1}
      ref={focus}
      role="dialog"
      aria-modal={true}
    >
      <CloseButton
        ariaLabel={translation.common.primarybutton.close}
        onClick={closeFeedbackForm}
        extraClassName="ui-max confirm-cancel-image-wrapper"
      />
      <h3 className="hw-h3 hw-text-title feedback-heading">
        {translation.common.feedback.form.header}
      </h3>
      <div className="feedback-rating-container">
        {ratings.map((rating) => (
          <div key={`rating-${rating}`}>
            <label
              className="hidden"
              htmlFor={`${rating}-button`}
              id={`rating-label-${rating}`}
            >
              {translation.common.feedback.ratingbuttons[rating]}
            </label>
            <Button
              id={`${rating}-button`}
              ariaLabelledBy={`feedback-heading rating-label-${rating}`}
              extraClassName="feedback-icon"
              onClick={(e) => {
                e.preventDefault();
                updateFormData({ chosenRating: rating });
              }}
              buttonStyle={buttonStyles.transparent}
            >
              {setCorrectRatingIconStyle(
                rating,
                formData.chosenRating ? formData.chosenRating : 0
              )}
            </Button>
          </div>
        ))}
      </div>
      <div
        className={`hw-label form-input__label feedback-label hw-block--mb-medium-3-responsive${
          formData.commentValidation.result === ValidationType.INVALID_FIELD
            ? ' hw-label--error'
            : ''
        }`}
      >
        <label htmlFor="feedback-comment">
          {translation.common.feedback.comment.label}
        </label>
        <textarea
          name="feedback-comment"
          id="feedback-comment"
          className={`hw-input standard-message feedback-input${
            formData.commentValidation.result === ValidationType.INVALID_FIELD
              ? ' hw-input--error'
              : ''
          }`}
          cols={40}
          rows={4}
          onChange={onChangeText}
          value={formData.comment}
          placeholder={translation.common.feedback.comment.placeholder}
        />
        {formData.commentValidation.result === ValidationType.INVALID_FIELD && (
          <InputErrorText
            errorMessage={formData.commentValidation.errorMessage}
          />
        )}
      </div>
      <p
        dangerouslySetInnerHTML={{
          __html: translation.common.feedback.disclaimer,
        }}
        id="feedback-form-comment"
        className="feedback-text hw-block--mt-small-2"
      />
      <div className="feedback-text hw-block--mb-medium-3-responsive">
        <span
          dangerouslySetInnerHTML={{
            __html: translation.common.feedback.disclaimer_part2,
          }}
        />{' '}
        <a
          className="hw-link feedback-text"
          href="mailto:kundeservice@digipost.no?subject=Henvendelse om Posten signering"
        >
          <span>kundeservice@digipost.no</span>
        </a>
      </div>
      <div className="feedback-form__buttons">
        <PrimaryButton
          extraClassName="feedback-form__buttons__submit"
          width={widths.full}
          onClick={onSubmitFeedback}
        >
          {translation.common.feedback.form.submit}
        </PrimaryButton>
        <SecondaryOutlinedButton
          extraClassName="feedback-form__buttons__close"
          onClick={closeFeedbackForm}
        >
          {translation.common.primarybutton.close}
        </SecondaryOutlinedButton>
      </div>
    </form>
  );
};

interface FeedbackFormProps {
  formData: {
    chosenRating?: number;
    comment?: string;
    commentValidation: Validation;
  };
  updateFormData: (form: { chosenRating?: number; comment?: string }) => void;
  onSubmitFeedback: () => void;
  closeFeedbackForm: () => void;
}

export default FeedbackForm;
