import { FunctionComponent, PropsWithChildren } from 'react';

import { addClassesFromProps } from '../../basicFunctions';
import { useHamburgerContext } from '../PostenHeader';

interface HamburgerElementProps {
  selected?: boolean;
  extraClassName?: string;
}

const HamburgerElement: FunctionComponent<
  HamburgerElementProps & PropsWithChildren
> = ({ children, extraClassName }) => {
  const { setHamburgerVisibility } = useHamburgerContext();
  return (
    <div
      role="presentation"
      className={`hamburger__list-item${addClassesFromProps(extraClassName)}`}
      onClick={() => setHamburgerVisibility(false)}
    >
      {children}
    </div>
  );
};

export default HamburgerElement;
